import { Link } from "gatsby";
import React from "react";

const ProjectHover = ({ work }) => (
  <Link
    to="/works"
    className="flex cursor-pointer justify-center text-custom-teal bg-custom-brown 
  absolute inset-0 m-auto
  opacity-0 md:relative md:opacity-100
  hover:opacity-100 bg-opacity-100 duration-300">
    <div className="flex justify-between items-end mb-12 lg:mb-20 ml-10 p-5 text-left w-4/5 md:m-0 md:w-full">
      <div className="flex flex-col-reverse w-full md:flex-row md:justify-between ">
        <div className="flex flex-col flex-wrap md:items-end md:flex-row">
          <h1 className="font-OswaldRegular uppercase m-0 mb-4 pr-2 inline-block md:m-0">
            {work.title},
          </h1>
          <h2 className="font-OswaldLight uppercase m-0 inline-block">
            {work.city}
          </h2>
        </div>
        <p className="font-LatoRegular text-lg mb-6 md:m-0 md:ml-4">{work.info}</p>
      </div>
      <div
        className="flex justify-center items-center font-LatoBold font-semibold md:hidden border-custom-teal border-2 w-28 h-24"
        style={{
          borderRadius: "50%"
        }}>
        VIEW
      </div>
    </div>
  </Link>
);

export default ProjectHover;
