import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Social = () => (
  <div
    className="flex mb-5 justify-start flex-col items-start 
                          md:flex-row md:items-center md:justify-center
                          lg:pl-16 xl:pl-32">
    <h5 className="text-custom-brown mb-0 mr-4 pd-2 font-LatoRegular font-thin md:m-0">
      Follow Us
    </h5>
    <div className="flex flex-row">
      <Link to="/#">
        <StaticImage
          className="m-1 w-12 md:w-10"
          src="../../../assets/images/social/mail.svg"
          alt="Feature Circle "
          formats={["auto", "avif", "webp"]}
        />
      </Link>
      <Link to="/#">
        <StaticImage
          className="m-1 w-12 md:w-10 "
          src="../../../assets/images/social/twitter.svg"
          alt="Feature Circle "
          formats={["auto", "avif", "webp"]}
        />
      </Link>
      <Link to="/#">
        <StaticImage
          className="m-1 w-12 md:w-10"
          src="../../../assets/images/social/facebook.svg"
          alt="Feature Circle "
          formats={["auto", "avif", "webp"]}
        />
      </Link>
      <Link to="/#">
        <StaticImage
          className="m-1 w-12 md:w-10 "
          src="../../../assets/images/social/instagram.svg"
          alt="Feature Circle "
          formats={["auto", "avif", "webp"]}
        />
      </Link>
    </div>
  </div>
);

export default Social;
