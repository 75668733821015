import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Nav = () => (
  <div className="flex justify-between align-center mr-10 md:hidden">
    <div className="flex flex-col pl-10 pr-4 lg:pr-10 xl:pr-16 justify-items-start self-center">
      <StaticImage
        src="../../../assets/images/home/featureCircle.svg"
        alt="Feature Circle "
        className="w-40"
        formats={["auto", "avif", "webp"]}
        objectFit={"contain"}
      />
    </div>
    {/* <div className="flex flex-col pt-10 pb-10 pl-0 pr-10">
      <ul className="text-2xl font-OswaldRegular text-custom-brown text-center md:text-left m-0">
        <Link to="/#products">
          <li>PRODUCTS</li>
        </Link>
        <li>WORK</li>
        <li>WHO WE ARE</li>
        <li>CONNECT</li>
      </ul>
    </div> */}
  </div>
);

export default Nav;
