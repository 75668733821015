import * as React from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Clients from "../components/Home/Clients";
import Counter from "../components/Home/Counter";
import Feature from "../components/Home/Feature";
import Products from "../components/Home/Products";
import Testimonials from "../components/Home/Testimonials";
import Works from "../components/Home/Works";
import DesktopNav from "../components/Navbar/desktop";

const IndexPage = () => {
  const [showStickyNav, setShowStickyNav] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);
  function scrollHandler(event) {
    if (window.pageYOffset >= window.screen.height) {
      setShowStickyNav(true);
    } else {
      setShowStickyNav(false);
    }
  }
  return (
    <>
      {showStickyNav ? (
        <div className="h-screen">
          <DesktopNav />
        </div>
      ) : (
        <Hero />
      )}
      <Products />
      <Feature />
      <Counter />
      <Works />
      <Testimonials />
      <Clients />
      <Footer />
    </>
  );
};

export default IndexPage;
