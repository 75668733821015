import React from "react";

const Email = () => (
  <div>
    <div className="flex flex-row md:justify-center justify-start">
      <input
        className=" text-md border-custom-brown border-2 h-10 bg-transparent font-LatoRegular p-2 text-custom-brown placeholder-custom-brown md:w-3/5 w-48"
        placeholder="Email Id"
        type="email"
      />
      <input
        className=" text-md border-custom-brown border-2 h-10 bg-custom-brown font-LatoRegular p-2 text-custom-teal"
        placeholder="Email Id"
        type="button"
        value="Subscribe"
      />
    </div>
  </div>
);

export default Email;
