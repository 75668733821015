import React from "react";
import { Link } from "gatsby";

const CopyRight = () => (
  <div className="flex flex-row mt-20 justify-between w-full pr-3 text-center text-custom-brown md:justify-center">
    <div>
      <h5 className="mb-0 ml-0 font-LatoRegular mr-40 font-extralight md:mr-10">
        @{new Date().getFullYear()} smiith and mould.All rights reserved.
      </h5>
    </div>
    <div className="flex flex-row">
      <Link to="/#">
        <h5 className="mb-0 mr-4 font-LatoRegular font-thin md:mr-2">Site Map</h5>
      </Link>
      <Link to="/#">
        <h5 className="mb-0 font-LatoRegular font-thin">Privacy Policy</h5>
      </Link>
    </div>
  </div>
);

export default CopyRight;
