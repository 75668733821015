import React, { useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { StaticImage } from "gatsby-plugin-image";

const Testimonials = () => {
  const carourselRef = useRef(null);
  return (
    <div
      id="testimonials"
      className="flex flex-col w-full justify-items-center items-center pr-2 pl-2">
      <div className="flex-row justify-center text-center mt-5 p-5">
        <h2 className="herotitleOutline font-OswaldRegular uppercase text-custom-teal">
          Testimonials
        </h2>
      </div>

      <div className="flex flex-row m-auto p-4 w-full lg:w-2/3">
        <div
          onClick={() => {
            carourselRef.current.decrement();
          }}
          className="hover: cursor-pointer flex self-stretch place-self-stretch align-middle md:ml-auto pb-40">
          <span className="place-self-center text-4xl font-OswaldRegular text-custom-teal">
            &#60;
          </span>
        </div>
        <div className="w-5/6 m-auto">
          <Carousel
            autoPlay={true}
            ref={carourselRef}
            showArrows={false}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            infiniteLoop={true}>
            {[1, 2].map((val, i) => (
              <div key={i}>
                <div className="p-12 relative">
                  <div className="absolute top-0 left-0">
                    <StaticImage
                      src="../../../assets/images/quotes/open-quote.png"
                      quality={95}
                      className="w-16"
                      formats={["auto", "webp", "avif"]}
                      objectFit={"contain"}
                      alt="Open Quote"
                    />
                  </div>
                  <span className="pl-4 pr-4 pt-8 pb-8 font-LatoRegular text-custom-teal text-lg text-center">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
                    odio. Quisque volutpat mattis eros. Nullam malesuada erat ut
                    turpis. Suspendisse urna nibh, viverra non, semper suscipit,
                    posuere a, pede.Lorem ipsum dolor sit amet, consectetuer
                    adipiscing elit. Donec odio.
                  </span>
                  <div className="absolute bottom-0 right-0">
                    <StaticImage
                      src="../../../assets/images/quotes/close-quote.png"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      objectFit={"contain"}
                      alt="Close Quote"
                      className="w-16"
                    />
                  </div>
                </div>
                <h3 className="font-LatoBold text-custom-teal text-center text-3xl">
                  ---
                </h3>
                <h4 className="font-LatoBold text-custom-teal text-center text-2xl">
                  Sachin Gala, Architect
                </h4>
              </div>
            ))}
          </Carousel>
        </div>
        <div
          onClick={() => {
            carourselRef.current.increment();
          }}
          className="hover: cursor-pointer  flex self-stretch place-self-stretch align-middle md:mr-auto pb-40">
          <span className="place-self-center text-4xl font-OswaldRegular text-custom-teal">
            &#62;
          </span>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
