import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import React from "react";
import "./style.css";
import GridIcon from "../../../assets/images/home/product/grid_blue2.png";
import BaffleIcon from "../../../assets/images/home/product/baffle_blue.png";
import LineAIIcon from "../../../assets/images/home/product/lineai_blue.png";

const ProductTitle = ({ icon, name }) => (
  <div className="flex w-4/5 mt-4 px-4 m-auto items-center">
    <img src={icon} alt={name} className="h-6 w-6 mt-5" />
    <div className="h-[3px] flex-grow bg-custom-yellow mx-2"></div>
    <h1 className="m-0 font-OswaldRegular text-custom-teal-head font-normal uppercase text-2xl">
      {name}
    </h1>
  </div>
);

const Products = () => {
  const handleScroll = () => {
    // document.getElementById("singleRowImage").offsetWidth * 0.75
    document.getElementById("imageSlider").scrollLeft += screen.width * 0.65;
  };
  return (
    <div id="products" className="bg-products-pattern bg-cover mb-10 pb-10">
      <div className="container m-auto">
        <div className="flex-row justify-center text-center pt-14">
          <h1 className="herotitleOutline font-OswaldRegular text-5xl text-custom-teal tracking-wider">
            PRODUCTS
          </h1>
        </div>
        {/* Desktop */}
        <div className="md:hidden grid grid-cols-3 gap-4 text-center">
          <div>
            <Link to="/product_categories/grid">
              <div className="product flex flex-col mt-8">
                <StaticImage
                  src="../../../assets/images/home/product1.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  objectFit={"contain"}
                  alt="Product 1 Image"
                />
                <ProductTitle icon={BaffleIcon} name="BAFFLES" />
              </div>
            </Link>
          </div>
          <div>
            <h3 className="m-auto mt-5 mb-8 max-w-xs text-custom-teal font-LatoRegular text-lg font-light">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod{" "}
            </h3>
            <Link to="/product_categories/grid">
              <div className="product flex flex-col">
                <StaticImage
                  src="../../../assets/images/home/product2.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  objectFit={"contain"}
                  alt="Product 2 Image"
                />
                <ProductTitle icon={GridIcon} name="Grid" />
              </div>
            </Link>
            <svg
              className="h-10 w-6 m-auto mb-2 text-custom-teal"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 20l-7 6m0 0l-7-7m7 7V3"
              />
            </svg>
          </div>
          <div>
            <Link to="/product_categories/grid">
              <div className="product flex flex-col mt-8">
                <StaticImage
                  src="../../../assets/images/home/product3.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  objectFit={"contain"}
                  alt="Product 3 Image"
                />
                <ProductTitle icon={LineAIIcon} name="LINEAI" />
              </div>
            </Link>
          </div>
        </div>
        {/* Mobile */}
        <div className="lg:hidden">
          <div className="pt-5">
            <h3 className="text-center m-auto w-2/3 text-custom-teal font-LatoRegular text-lg font-light">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod{" "}
            </h3>
          </div>
          <div
            id="imageSlider"
            style={{
              cursor: "grab"
            }}
            className="overflow-x-scroll horizontalScroll flex py-4 pb-8 mt-5">
            <div className="pt-2 mt-2 w-72 h-80 flex-shrink-0 flex flex-col">
              <StaticImage
                id="singleRowImage"
                src="../../../assets/images/home/product1.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 2 Image"
              />
              <StaticImage
                src="../../../assets/images/home/product1Label.svg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 1 Label"
              />
            </div>
            <div className="pt-2 mt-16 w-72 h-80 flex-shrink-0 flex flex-col">
              <StaticImage
                src="../../../assets/images/home/product2.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 2 Image"
              />
              <StaticImage
                src="../../../assets/images/home/product2Label.svg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 2 Label"
              />
            </div>
            <div className="pt-2 mt-2 w-72 h-80 flex-shrink-0 flex flex-col">
              <StaticImage
                src="../../../assets/images/home/product3.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 3 Image"
              />
              <StaticImage
                src="../../../assets/images/home/product3Label.svg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                objectFit={"contain"}
                alt="Product 3 Label"
              />
            </div>
          </div>
          <div onClick={handleScroll}>
            <svg
              className="h-6 w-6 m-auto mt-5 text-custom-teal hover: cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
