import React, { useState } from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import * as Style from "./HeroCorousal.module.css";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import LogoImage from "../../assets/images/home/logo.svg";
import FeatureCircleImage from "../../assets/images/home/featureCircle.svg";
const Hero = () => {
  const [curr, setCurr] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const query = useStaticQuery(graphql`
    {
      allHomeJson {
        nodes {
          hero {
            src {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
              }
            }
            alt
          }
        }
      }
    }
  `);

  const allImages = query.allHomeJson.nodes[0].hero;
  const altTexts = query.allHomeJson.nodes[0].hero;
  let total = query.allHomeJson.nodes[0].hero.length;

  return (
    <div
      id="home"
      className={`md:h-full md:flex-wrap bg-custom-teal flex w-full ${Style.heroCorousalContainer}`}>
      <div
        className={`flex flex-col justify-between pl-20 md:p-0 lg:pt-10 lg:pb-10 lg:w-2/5 ${Style.heroTextContainer}`}>
        <div
          onClick={handleMenu}
          className="lg:hidden absolute top-10 left-4 m-1 hover: cursor-pointer">
          <svg viewBox="0 0 110 80" width="30" height="30" fill="#E0B65F">
            <rect width="100" height="10" />
            <rect y="30" width="80" height="10" />
            <rect y="60" width="60" height="10" />
          </svg>
        </div>

        {/* Desktop Part */}
        <div className="md:mt-3 md:flex md:justify-center md:items-center mr-0">
          <Link to="/#home">
            <img src={LogoImage} alt="Site Logo" className={Style.heroLogo} />
          </Link>
        </div>
        <div className="lg:hidden lg:mt-5">
          <div className={`top-16 z-10 absolute right-2`}>
            <img
              src={FeatureCircleImage}
              alt="Feature Circle"
              className={`h-20 w-20 ${Style.featureCircle}`}
            />
          </div>
        </div>

        {/* // Desktop List */}
        <ul className="md:hidden text-2xl font-OswaldRegular text-custom-brown m-0 py-3 lg:mt-8">
          <Link to="/#products">
            <li className="py-2 ">PRODUCTS</li>
          </Link>
          <Link to="/works">
            <li className="py-2">WORK</li>
          </Link>
          <Link to="/about">
            <li className="py-2">WHO WE ARE</li>
          </Link>
          <Link to="/contact-us">
            <li className="py-2">CONNECT</li>
          </Link>
        </ul>

        {/* Mobile Part */}
        {isMenuOpen ? (
          <div className="lg:hidden z-10 absolute top-24 mt-2 left-0 w-48 bg-custom-teal pt-2 pl-1 pr-2 pb-2">
            <ul className="text-lg font-OswaldRegular text-custom-brown">
              <Link to="/#products">
                <li className="p-2">PRODUCTS</li>
              </Link>
              <li className="p-2">WORK</li>
              <Link to="/about">
                <li className="p-2">WHO WE ARE</li>
              </Link>
              <Link to="/contact-us">
                <li className="p-2">CONNECT</li>
              </Link>
            </ul>
          </div>
        ) : null}
        <div className="lg:hidden relative flex w-full h-96">
          <GatsbyImage
            alt={altTexts[curr].alt}
            image={getImage(allImages[curr].src)}
          />
        </div>
        <div
          style={{
            marginTop: "-1rem"
          }}
          className="lg:hidden text-center">
          <StaticImage
            className="hover: cursor-pointer"
            src="../../assets/images/home/leftArrow.svg"
            quality={95}
            onClick={() =>
              setCurr((curr - 1) % total < 0 ? total - 1 : (curr - 1) % total)
            }
            className="w-8 h-8"
            // style={{ width: "objectFit", height: "objectFit" }}
            formats={["auto", "webp", "avif"]}
            objectFit={"contain"}
            alt="Left Arrow"
          />
          <StaticImage
            className="hover: cursor-pointer"
            src="../../assets/images/home/rightArrow.svg"
            quality={95}
            onClick={() => setCurr((curr + 1) % total)}
            className="w-8 h-8"
            // style={{ width: "objectFit", height: "objectFit" }}
            formats={["auto", "webp", "avif"]}
            objectFit={"contain"}
            alt="Right Arrow"
          />
        </div>

        {/* Desktop Caption Part */}
        <h1
          className="w-50 text-3xl mt-12 mb-12 font-OswaldLight text-white font-light leading-10
        md:m-9 md:mt-6 md:text-center md:text-xl xl:p-1 xl:w-5/6 heroCaption">
          we don't just add <br />
          personality &amp; finesse <br className="md:hidden" /> but also create
          <br className="md:hidden" /> a safe heaven
        </h1>
      </div>

      <div className="md:hidden flex justify-center transform translate-x-1/2 z-10">
        <div className="flex h-full">
          <img
            src={FeatureCircleImage}
            alt="Feature Circle"
            className={Style.featureCircle}
          />
        </div>
        <div className="flex h-full items-end justify-around absolute top-0 min-w-max">
          <StaticImage
            className={`hover: cursor-pointer ${Style.arrowImages}`}
            src="../../assets/images/home/leftArrow.svg"
            quality={95}
            onClick={() =>
              setCurr((curr - 1) % total < 0 ? total - 1 : (curr - 1) % total)
            }
            style={{ width: "objectFit", height: "objectFit" }}
            formats={["auto", "webp", "avif"]}
            objectFit={"contain"}
            alt="Left Arrow"
          />
          <StaticImage
            className={`hover: cursor-pointer ${Style.arrowImages}`}
            src="../../assets/images/home/rightArrow.svg"
            quality={95}
            onClick={() => setCurr((curr + 1) % total)}
            style={{ width: "objectFit", height: "objectFit" }}
            formats={["auto", "webp", "avif"]}
            objectFit={"contain"}
            alt="Right Arrow"
          />
        </div>
      </div>

      <div className={`md:hidden flex w-full h-full`}>
        <GatsbyImage
          alt={altTexts[curr].alt}
          image={getImage(allImages[curr].src)}
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default Hero;
