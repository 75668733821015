import React from "react";
import { CopyRight, Email, Social, Nav } from "./components";

const Footer = () => {
  return (
    <div id="footer" className="w-full bg-custom-teal z-20">
      <div className="flex flex-row justify-between align-center p-5 md:flex-col xl:pr-20 xl:pl-20 ">
        <Nav />
        <div className="m-auto lg:m-0">
          <div className="flex flex-row align-center justify-between text-center pt-10 md:flex-col-reverse ">
            <Email />
            <Social />
          </div>
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default Footer;
