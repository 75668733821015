import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import LogoImage from "../../assets/images/home/logo.svg";

const DesktopNav = () => {
  return (
    <div className="fixed w-full z-50">
      <div className="md:hidden">
        <div className="grid grid-cols-12 gap-5 bg-custom-teal p-4">
          <div className="col-span-3 pr-3 pl-3">
            <Link to="/#home">
              <img
                src={LogoImage}
                alt="Site Logo"
                className="h-20 w-[92%] m-0 p-0"
              />
            </Link>
          </div>
          <div className="grid grid-cols-4 gap-10 col-span-7 m-auto uppercase text-center text-custom-brown text-lg font-OswaldRegular">
            <Link to="/#products">
              <div>products</div>
            </Link>
            <Link to="/works">
              <div>work</div>
            </Link>
            <Link to="/about">
              <div>who we are</div>
            </Link>
            <Link to="/contact-us">
              <div>connect</div>
            </Link>
          </div>
          <div className="col-span-2 m-auto flex">
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                color="#E0B65F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
              />
            </svg>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                color="#E0B65F"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNav;
