import React from "react";

const Clients = () => {
  return (
    <div id="clients" className="flex flex-col w-full bg-yellow-50">
      <div className="flex-row justify-center text-center mt-5 p-5">
        <h2 className="text-md herotitleOutline font-OswaldRegular uppercase text-custom-teal">
          Clients we worked with
        </h2>
      </div>
      <div className="flex flex-row justify-center">
        <div className="grid grid-cols-4 w-full justify-center m-10 gap-4 sm:grid-cols-2 md:grid-cols-3">
          {Array.apply(null, Array(10)).map((val, i) => (
            <div className="h-16 bg-custom-teal m-3" key={i}></div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Clients;
