import React from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { StaticQuery, graphql } from "gatsby";
import "./style.css";

const Counter = () => {
  const { ref, inView, entry } = useInView({
    triggerOnce: true,
    threshold: 0
  });
  return (
    <div>
      <div className="py-16 px-2 lg:p-10 bg-custom-teal grid grid-cols-4 text-custom-brown">
        <StaticQuery
          query={graphql`
            {
              allHomeJson {
                nodes {
                  counters {
                    val
                    text
                  }
                }
              }
            }
          `}
          render={({ allHomeJson: { nodes } }) => {
            return nodes[0].counters.map(({ val, text }) => {
              return (
                <div
                  key={text + val}
                  className="grid grid-cols-1 text-xs text-center">
                  <h1 className="font-OswaldRegular" ref={ref}>
                    {inView && (
                      <CountUp className="counter" end={val} duration={1} />
                    )}
                  </h1>
                  <h3 className="font-OswaldLight counter-text">{text}</h3>
                </div>
              );
            });
          }}
        />
      </div>
    </div>
  );
};

export default Counter;
