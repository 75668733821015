import React, { useEffect, useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ProjectHover from "./ProjectHover";
const Works = () => {
  const [width, setWidth] = useState(0);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <div id="works" className="flex flex-col w-full bg-yellow-50">
      <div className="flex-row justify-center text-center mt-5 p-5">
        <h2 className="herotitleOutline font-OswaldRegular text-4xl text-custom-teal">
          WORK
        </h2>
      </div>
      <div className="flex relative flex-row self-center text-center p-5 md:w-2/5 sm:w-full justify-center">
        <h4 className="m-0 text-custom-teal font-LatoRegular">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore{" "}
        </h4>
      </div>
      <div className="flex flex-col md:flex-row w-full mb-10">
        {/* will be changed later to fetch data from mongoDB/S3 */}
        <StaticQuery
          query={graphql`
            {
              allHomeJson {
                nodes {
                  works {
                    image {
                      childImageSharp {
                        gatsbyImageData(formats: [AUTO, WEBP, AVIF])
                      }
                    }
                    title
                    info
                    city
                  }
                }
              }
            }
          `}
          render={({ allHomeJson: { nodes } }) => {
            const works = nodes[0].works.slice(0, 2);
            const workComponent = [];
            for (let index in works) {
              workComponent.push(
                <div
                  className={`relative md:p-0 md:w-full w-1/2 md:${
                    index < 1 ? "block" : "hidden"
                  }`}
                  key={index}>
                  <GatsbyImage
                    alt={works[index].title}
                    image={getImage(works[index].image)}
                    className="w-full md:h-auto h-full"
                  />
                  <ProjectHover work={works[index]} />
                </div>
              );
            }

            return <div className="flex flex-row w-full">{workComponent}</div>;
          }}
        />
      </div>
      <div className="flex flex-row w-full justify-center mb-10">
        <Link to="/works">
          <p className="border-custom-teal border-2  text-custom-teal p-2 pl-4 pr-4 font-LatoRegular uppercase font-bold text-sm">
            View All
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Works;
