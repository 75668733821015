import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./style.css";

const Feature = () => {
  let cardWidth = "w-full";
  return (
    <div id="features">
      <div className="md:p-5 pl-16 pr-16 pt-10 pb-10 xl:pl-28 xl:pr-28 xl:pt-15 xl:pb-10 2xl:pl-48">
        <div className="text-right lg:text-right mb-5 lg:mb-0">
          <h1 className="md:text-4xl text-custom-teal font-OswaldRegular lg:text-6xl">
            we bring artistry in metal.
          </h1>
          <h3 className="md:text-left md:text-md lg:w-3/5 text-custom-teal font-LatoRegular ml-auto font-light mt-10">
            Our company constantly evolves to booming technology, maintaining the
            market power and consistently developing fruitful associations and
            trustworthy relationships with people.
          </h3>
          <Link to="/about">
            <button className="md:my-5 border-custom-teal border-2 bg-custom-brown text-custom-teal p-2 pl-3 pr-3 font-LatoRegular">
              KNOW MORE
            </button>
          </Link>
        </div>

        <div className="grid grid-cols-2 justify-items-center w-3/4 md:m-auto sm:w-full lg:grid-cols-3 lg:w-2/3 lg:transform lg:-translate-y-14 xl:-translate-y-28 xl:pt-16">
          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle1.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 1 Image"
            />
            <h4 className="feature-text md:text-md">AntiMicrobal Properties</h4>
          </div>

          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle2.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 2 Image"
            />
            <h4 className="feature-text md:text-md">Acoutical Absorption</h4>
          </div>

          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle3.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 3 Image"
            />
            <h4 className="feature-text md:text-md">Corrosion Resistance</h4>
          </div>

          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle4.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 4 Image"
            />
            <h4 className="feature-text md:text-md">Air Tight</h4>
          </div>

          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle5.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 5 Image"
            />
            <h4 className="feature-text md:text-md">Environment Sustainability</h4>
          </div>

          <div className={`md:h-48 md:${cardWidth} flex flex-col`}>
            <StaticImage
              src="../../../assets/images/home/feature/featureCircle6.svg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              objectFit={"contain"}
              alt="Feature Circle 6 Image"
            />
            <h4 className="feature-text md:text-md">Easy Cleaning</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
